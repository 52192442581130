import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.css']
})
export class FloorplanComponent implements OnInit {

 
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  floorPlanImages: Array<string> = [
    '',
    '',
    '',
    '',
    '',
    ''
  ];
  selectedProperty: any;

  constructor(
    private modalService: NgbModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty() : null;
    if(this.selectedProperty && this.selectedProperty.floorPlanImages) {
      this.floorPlanImages = this.selectedProperty.floorPlanImages;
    }
  }

  uploadAws(event: any, index: number): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm'});
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((cropResp: {image: any, markDefault: boolean}) => {
      if(cropResp && cropResp.image) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = cropResp.image;
        modalRef.result.then((path: string) => {
          if (path) {
            if(this.floorPlanImages[index]) {
              this.floorPlanImages[index] = path;
            } else {
              const imageList = this.floorPlanImages.filter(item => item !== '');
           
                imageList.push(path);
              
              if(imageList.length < 6) {
                const length = 6 - imageList.length;
                for(var i=0 ; i < length; i++) {
                  imageList.push('');
                }
              }
              this.floorPlanImages = imageList;
            }
          }
        });
      }
    });
  }

  back(): void {
    this.showNextPage.emit(4);
  }

  submit(): void {
    let property = this.lookupModel.getVendorProperty();
    property.floorPlanimages = this.floorPlanImages;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(6);
  }


}
