import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
export class SwatchItem {
  swatchName: string;
}

@Component({
  selector: 'app-projecthighlights',
  templateUrl: './projecthighlights.component.html',
  styleUrls: ['./projecthighlights.component.css']
})
export class ProjecthighlightsComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  swatchList: Array<SwatchItem> = [];
  constructor(
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
  }

  addSwatch(): void {
    const newswatch = new SwatchItem();
    this.swatchList.unshift(newswatch);
  }

  deleteSwatch(index: number): void {
    this.swatchList.splice(index, 1);
  }

  back(): void {
    this.showNextPage.emit(6)
  }
  submit() : void {
    let property = this.lookupModel.getVendorProperty();
    property.projectHighlights = this.swatchList;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(8);
  }

}
