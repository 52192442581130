import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/common/services/common.service';
import { FileSizePipe } from '../../pipe/file-size.pipe';
@Component({
  selector: 'app-upload-video-progress',
  templateUrl: './upload-video-progress.component.html',
  styleUrls: ['./upload-video-progress.component.css']
})
export class UploadVideoProgressComponent implements OnInit {
 @Input() file: any;
  @Input () type:any
  progress: number = 0;
  fileSize: string;
  error: boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    private commonService: CommonService,
    private fileSizePipe: FileSizePipe
  ) { }

  ngOnInit() {
    // alert("calling upload-progress")
    const bucket = 'pillarsnewapp';
    this.commonService.uploadFile(this.file, 'https://pillarblocks.com:2050/upload').subscribe((rsp) => {
      this.callback(rsp, bucket);
    }, (err) => {
      if (bucket !== 'pillarsnewapp') {
        this.retryUpload('pillarsnewapp');
      } else {
        this.error = true;
      }
    });
  }

  callback = (rsp, bucketName) => {
    if (rsp.type === 0) {
      const evt = rsp.value;
      this.progress = Math.round(evt.loaded / evt.total * 100);
      this.fileSize = this.fileSizePipe.transform(evt.loaded) + ' of ' + this.fileSizePipe.transform(evt.total);
    }
    else {
      let path: string = '';
      path = rsp.value.filename;
      let pathType= this.type
      this.close({image:path,type:pathType});
    }
  };

  retryUpload(bucketName: string): void {
    this.commonService.uploadFile(this.file, 'https://pillarblocks.com:2050/upload').subscribe((rsp) => {
      this.callback(rsp, bucketName);
    }, (err) => this.error = true);
  }
  close(rsn?: any): void {
    this.activeModal.close(rsn);
  }

  cancel(): void {
    this.commonService.cancelUpload();
    this.close();
  }

}
