<div class="card padding-card t-align-l no-radius border">
    <!-- <span  *ngIf="isMobileLayout">
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2" style="margin-top: 20px;">Next</button>
        <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2" style="margin-top: 20px;">Back</button>
        </span> -->
        <div *ngIf="isMobileLayout" class="mobile-navigation">
            <!-- <div class="arrow back" (click)="back()">
              <i class="ri-arrow-left-line"></i>
            </div> -->
               <button type="button" class="arrow back" (click)="back()">
                <i class="ri-arrow-left-line"></i>
            </button>
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="submit()">Skip</button>
            <button type="button" class="arrow next" (click)="submit()">
                <i class="ri-arrow-right-line"></i>
              </button>
          </div>
    <div class="card-body">
        <h5 class="card-title mb-4">Property Highlights
            <!-- <button type="button" (click)="submit()" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-2 float-right mr-2">Next</button>
            <button type="button" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-2 float-right mr-2" (click)="back()">Back</button>
         -->
        </h5>
        <button (click)="addSwatch()" class="btn btn-primary btn-lg">Add New</button>
       
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                <label for="location">Enter Highlights</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location"
                        [(ngModel)]="swatchList[i].swatchName">
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="!isMobileLayout">
        <button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
        <button type="button" class="btn btn-primary btn-lg mb-2 float-right mr-2" (click)="back()">Back</button>
    </div>

</div>