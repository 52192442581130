<div class="container-fluid">
  <div class="pb-wrapper">
      <div class="tab-content" *ngIf="showPropertyFlag">

          <ng-container [ngSwitch]="propertyView">
              <app-rent-post-location *ngSwitchCase="1" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-location>
              <app-rent-post-details *ngSwitchCase="2" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-details>
              <app-rent-post-features *ngSwitchCase="3" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-features>
              <app-rent-post-gallery *ngSwitchCase="4" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-gallery>
                  <app-rent-post-video *ngSwitchCase="5" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-video>
              <!-- <app-floorplan *ngSwitchCase="5" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-floorplan> -->
              <app-rent-property-highlights *ngSwitchCase="6" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-property-highlights>
              <app-rent-property-location-highlights *ngSwitchCase="7" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-property-location-highlights>
              <!-- <app-specifications *ngSwitchCase="8" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-specifications> -->
              <app-rent-post-publish *ngSwitchCase="9" (formChange)="changeFormStatus($event)"
                  (showNextPage)="openPage($event)"></app-rent-post-publish>
          </ng-container>
      </div>
  </div>
</div>