import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { RsbService } from 'src/app/service/rsb.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { FeedbackComponent } from '../../feedback/feedback.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-rent-post-publish',
  templateUrl: './rent-post-publish.component.html',
  styleUrls: ['./rent-post-publish.component.css']
})
export class RentPostPublishComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  marketingForm: FormGroup;
  showCommissionInput: boolean = false;
  showGenerateOtp: boolean = false;
  showVerifyOtp: boolean = false;
  verifyBtnsuccess: boolean = false;
  resendOtpBtn: boolean = false;
  noCommission: boolean = false;
  statusMsg: string = "";
  otpval: any;
  allowMarketingOptions: any;
  isMobileLayout: boolean = false;
  todoListImg: boolean = true;
  disableSendOtpbtn: boolean = false;
  mobileNumber: any

  constructor(private fb: FormBuilder,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,

    private router: Router,
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    if (this.isMobileLayout) {
      this.todoListImg = false;
    }
    this.marketingForm = this.fb.group({
      allowMarketing: ['', Validators.required],
      commissionPercentage: [
        '',
        [
          Validators.required,            // Required field
          Validators.pattern('^[0-9]+$'), // Only numeric values
          Validators.min(1),              // Minimum value: 1%
          Validators.max(100)             // Maximum value: 100%
        ]
      ], otp: ['', Validators.required],

    });
    this.mobileNumber = this.lookupModel.getLoggedUser().personalDetails.mobile;
  }

  // Handles radio button change
  onMarketingOptionChange(option: string): void {
    this.allowMarketingOptions = option;
    if (option === 'yes') {
      this.showCommissionInput = true;
      this.showGenerateOtp = true;
      this.showVerifyOtp = false;
      this.resendOtpBtn = false;
      this.verifyBtnsuccess = false;
      this.noCommission = false;
      this.marketingForm.get('commissionPercentage')?.enable();
    } else {
      this.showCommissionInput = false;
      this.showGenerateOtp = false;
      this.showVerifyOtp = false;
      this.verifyBtnsuccess = false;
      this.noCommission = true;
      this.marketingForm.reset();
    }
  }

  sendOtp(): void {
    const personalDetails = this.lookupModel.getLoggedUserPersonalDetails();
    const mobileNumber = personalDetails.mobile;
    this.rsbService.sendDelarationMobileOtp().subscribe((rsp) => {
    })
    this.disableSendOtpbtn = true;
    this.showVerifyOtp = true;
    this.resendOtpBtn = true;
  }

  reSendOtp(): void {
    const personalDetails = this.lookupModel.getLoggedUserPersonalDetails();
    const mobileNumber = personalDetails.mobile;
    this.rsbService.resendDelarationMobileOtp().subscribe((rsp) => {
    })
    this.showVerifyOtp = true;
    this.resendOtpBtn = true;
  }


  verifyOtpBtn(): void {
    const personalDetails = this.lookupModel.getLoggedUserPersonalDetails();
    const mobileNumber = personalDetails.mobile;
    const otp = this.otp.value;
    this.otpval = this.otp.value;
    this.rsbService.verifyDelarationMobileOtp(mobileNumber, otp).subscribe((rsp: any) => {
      if (rsp.statusCode === 0) {
        this.statusMsg = "OTP Verified Successfully..."

        this.verifyBtnsuccess = true;
      }
      else {
        this.statusMsg = "OTP Was Incorrect. Please Enter Valid OTP.."

      }
    })
  }
  publish(): void {
    this.rsbService.publishPlan().subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        // this.toastrService.success('Property has been published successfully.');
        // this.router.navigate(['myProperties']);
        // const modelRef =this.modalService.open(FeedbackComponent);

      }
    });


  }
  postPublihForAssociate(): void {
    const allowMarketing = this.allowMarketing.value;
    const commissionPercentage = this.commissionPercentage.value;
    const otp = this.otp.value;
    if (allowMarketing && commissionPercentage && otp) {
      const args = {
        allowMarketing: this.allowMarketing.value,
        commissionPercentage: this.commissionPercentage.value,
      }
      this.rsbService.postPublishForAssociate(args).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          // this.rsbService.sendSidebarMessage('openSidebar');

          this.toastrService.success('Property has been published successfully.');
          // this.router.navigate(['myProperties']);
          // const modalRef = this.modalService.open(FeedbackComponent, {
          //   backdrop: true,
          // }); 
          this.feedbackPopUP();

        }
        else {
          this.toastrService.error('Failed to submit data.');
        }
      });
      this.lookupModel.setPublishRoute("active")
      this.publish();
    }

    if (this.allowMarketingOptions === 'no') {
      const args = {
        allowMarketing: this.allowMarketing.value,
      }
      this.rsbService.postPublishForAssociate(args).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          // this.rsbService.sendSidebarMessage('openSidebar');

          this.toastrService.success('Property has been published successfully.');
          // this.router.navigate(['myProperties']);
         this.feedbackPopUP();

        } else {
          this.toastrService.error('Failed to submit data.');
        }
      })
      this.lookupModel.setPublishRoute("active")
      this.publish();

    }

  }
  feedbackPopUP(){
    Swal.fire({
      title: 'How was your experience?',
      // icon: 'question',
      html: `
        <div style="display: flex; justify-content: space-around;">
         <div id="terrible" style="text-align: center; cursor: pointer;">
            <img src="assets/img/angry.png" style="width: 50px; height: 50px;">
            <p>Terrible</p>
          </div>
          <div id="bad" style="text-align: center; cursor: pointer;">
            <img src="assets/img/confusion.png" style="width: 50px; height: 50px;">
            <p>Bad</p>
          </div>
          <div id="ok" style="text-align: center; cursor: pointer;">
            <img src="assets/img/confusion.png" style="width: 50px; height: 50px;">
            <p>Ok</p>
          </div>
          <div id="good" style="text-align: center; cursor: pointer;">
            <img src="assets/img/happy.png" style="width: 50px; height: 50px;">
            <p>Good</p>
          </div>
          <div id="excellent" style="text-align: center; cursor: pointer;">
            <img src="assets/img/happy.png" style="width: 50px; height: 50px;">
            <p>Excellent</p>
          </div>

    
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true, // Display the close (cross) icon
      allowOutsideClick: true,
      willClose: () => {
        // Navigate to a different route when the alert is dismissed
        this.router.navigate(['myProperties']);
      }
    })
    

    document.getElementById('excellent')?.addEventListener('click', () => {
      this.handleFeedback('excellent');
    });
    document.getElementById('good')?.addEventListener('click', () => {
      this.handleFeedback('good');
    });
    document.getElementById('bad')?.addEventListener('click', () => {
      this.handleFeedback('bad');
    });
    document.getElementById('terrible')?.addEventListener('click', () => {
      this.handleFeedback('terrible');
    });
    document.getElementById('ok')?.addEventListener('click', () => {
      this.handleFeedback('ok');
    });
  }
  handleFeedback(feedbackType: string) {
    this.router.navigate(['myProperties']);
    Swal.close();

    this.rsbService.feedbackValue(feedbackType).subscribe(response => {
      console.log(`${feedbackType} feedback submitted`, response);
      this.router.navigate(['myProperties']);
    }, error => {
      console.error(`Error submitting ${feedbackType} feedback`, error);
    });
  }

  goBack(): void {
    this.showNextPage.emit(7)
  }
  get allowMarketing() { return this.marketingForm.get('allowMarketing'); }
  get commissionPercentage() { return this.marketingForm.get('commissionPercentage'); }
  get otp() { return this.marketingForm.get('otp'); }
}