
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function propertyRangeValidator(minControlName: string, maxControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minControl = control.get(minControlName);
      const maxControl = control.get(maxControlName);
  
      if (minControl && maxControl && minControl.value !== null && maxControl.value !== null) {
        const min = parseFloat(minControl.value);
        const max = parseFloat(maxControl.value);
  
        if (min >= max) {
          return { 'propertyRange': true }; // return error if priceMin is not less than priceMax
        }
      }
      return null; // return null if validation passes
    };
  }