<div class="container mt-4">
    <div class="row">
      <div class="col-md-8">
        <h4 class="mb-2">
          <i class="ri-file-list-3-line"></i>
          Declaration of <span class="text-danger">Property Marketing</span> &
        </h4>
        <h4><span class="text-primary">Commission Rate - On Sale Of Property</span></h4>
        <form [formGroup]="marketingForm" (ngSubmit)="postPublihForAssociate()">
          <!-- Radio Buttons -->
          <div class="form-group">
            <label>Will You Allow Nexus Partners to Market Your Property?</label>
            <div class="form-check">
              <input type="radio" formControlName="allowMarketing" id="yesOption" value="yes" class="form-check-input"
                (change)="onMarketingOptionChange('yes')" />
              <label for="yesOption" class="form-check-label">Yes</label>
            </div>
            <div class="form-check">
              <input type="radio" formControlName="allowMarketing" id="noOption" value="no" class="form-check-input"
                (change)="onMarketingOptionChange('no')" />
              <label for="noOption" class="form-check-label">No</label>
            </div>
          </div>
  
          <!-- Commission Percentage -->
          <div class="form-group mt-3" *ngIf="showCommissionInput">
            <label for="commissionPercentage">
              How Much Percentage Will You Share on Sale Of Property as Commission?
            </label>
            <input type="text" formControlName="commissionPercentage" id="commissionPercentage" placeholder="Ex: 2%"
              class="form-control" />
            <!-- Validation Messages -->
            <div class="text-danger mt-1" *ngIf="marketingForm.get('commissionPercentage')?.errors">
              <small *ngIf="marketingForm.get('commissionPercentage')?.hasError('required')">
                Commission percentage is required.
              </small>
              <small *ngIf="marketingForm.get('commissionPercentage')?.hasError('pattern')">
                Only numeric values are allowed.
              </small>
              <small *ngIf="marketingForm.get('commissionPercentage')?.hasError('min')">
                The value must be at least 1%.
              </small>
              <small *ngIf="marketingForm.get('commissionPercentage')?.hasError('max')">
                The value cannot exceed 100%.
              </small>
            </div>
          </div>
  
          <!-- Generate OTP and Resend OTP -->
          <div *ngIf="showGenerateOtp">
            <input type="text" class="form-control" [value]="mobileNumber" readonly />
            <div class="mt-2">
              <button type="button" class="btn btn-primary mr-2" (click)="sendOtp()"
                [disabled]="!marketingForm.get('commissionPercentage')?.value || disableSendOtpbtn">Generate OTP</button>
              <button type="button" class="btn btn-primary" (click)="reSendOtp()"
                [disabled]="!marketingForm.get('commissionPercentage')?.value" *ngIf="resendOtpBtn">Re-Send OTP</button>
            </div>
          </div>
  
          <!-- OTP Verification -->
          <div class="form-group mt-3" *ngIf="showVerifyOtp">
            <input type="text" formControlName="otp" id="otp" placeholder="Enter OTP" class="form-control" />
            <!-- <input type="text" formControlName="otp" *ngIf="!verifyBtnsuccess" id="otp" placeholder="Enter OTP" class="form-control" /> -->
            <!-- <input type="text" formControlName="otp" *ngIf="verifyBtnsuccess" id="otp" placeholder="Enter OTP" value="{{otpval}}"  class="form-control" readonly/> -->
  
            <button type="button" class="btn btn-primary mt-2" (click)="verifyOtpBtn()"
              [disabled]="!marketingForm.get('otp')?.value">Verify OTP</button>
          </div>
          <p>{{statusMsg}}</p>
  
          <!-- Action Buttons -->
          <div class="mt-4">
            <button type="button" class="btn btn-secondary" (click)="goBack()">Go Back</button>
            <button type="submit" class="btn btn-success ml-2" *ngIf="verifyBtnsuccess || noCommission">Save &
              Submit</button>
          </div>
        </form>
      </div>
  
      <div class="col-md-4 d-flex align-items-center justify-content-center" *ngIf="todoListImg">
        <img src="assets/img/to-do-list.png" style="width: 150px; height: auto" alt="Checklist Image" class="img-fluid" />
      </div>
    </div>
  </div>