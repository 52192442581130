import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OwlModule } from 'ngx-owl-carousel';

import { AgmCoreModule } from '@agm/core';
import { NguCarouselModule } from '@ngu/carousel';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
import { PostLocationComponent } from './components/vendor/post-location/post-location.component';
import { PostDetailsComponent } from './components/vendor/post-details/post-details.component';
import { PostFeaturesComponent } from './components/vendor/post-features/post-features.component';
import { PostGalleryComponent } from './components/vendor/post-gallery/post-gallery.component';
import { PostPlanComponent } from './components/vendor/post-plan/post-plan.component';
import { PostPublishComponent } from './components/vendor/post-publish/post-publish.component';
import { LoaderService } from './core/loader.service';
import { InitialService } from './core/initial.service';
import { PropertylocationComponent } from './components/vendor/propertylocation/propertylocation.component';
import { VendorDashboardComponent } from './components/vendor/vendor-dashboard/vendor-dashboard.component';
import { LoanComponent } from './components/vendor/loan/loan.component';
import { FloorplanComponent } from './components/vendor/floorplan/floorplan.component';
import { ProjectfloorplanComponent } from './components/projects/projectfloorplan/projectfloorplan.component';
import { SpecificationsComponent } from './components/vendor/specifications/specifications.component';
import { PropertyhighlightsComponent } from './components/vendor/propertyhighlights/propertyhighlights.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MyPropertiesComponent } from './components/vendor/my-properties/my-properties.component';
import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
import { CommercialPropertiesComponent } from './components/vendor/commercial-properties/commercial-properties.component';
import { LeadcenterComponent } from './components/vendor/leadcenter/leadcenter.component';
import { DealsComponent } from './components/vendor/deals/deals.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
import { CompanyimagesComponent } from './components/companies/companyimages/companyimages.component';
import { AddsuccessfulprojectComponent } from './components/companies/addsuccessfulproject/addsuccessfulproject.component';
import { SuccessProjectsImagesComponent } from './components/companies/success-projects-images/success-projects-images.component';
import { AddOngoingProjectsComponent } from './components/companies/add-ongoing-projects/add-ongoing-projects.component';
import { OngoingProjectImagesComponent } from './components/companies/ongoing-project-images/ongoing-project-images.component';
import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { ProjectSidebarComponent } from './components/projects/project-sidebar/project-sidebar.component';
import { ProjectLocationComponent } from './components/projects/project-location/project-location.component';
import { ProjectinfoComponent } from './components/projects/projectinfo/projectinfo.component';
import { ProjectadditionalinfoComponent } from './components/projects/projectadditionalinfo/projectadditionalinfo.component';
import { ProjectgalleryComponent } from './components/projects/projectgallery/projectgallery.component';
import { BrochureComponent} from './components/projects/brochure/brochure.component';
import { ProjecthighlightsComponent } from './components/projects/projecthighlights/projecthighlights.component';
import { ProjectlocationhighlightsComponent } from './components/projects/projectlocationhighlights/projectlocationhighlights.component';
import { ProjectPublishComponent } from './components/projects/project-publish/project-publish.component';
import { ProjectSpecificationsComponent } from './components/projects/project-specifications/project-specifications.component';
import { UnitsComponent } from './components/projects/units/units.component';
import { PropertysidebarComponent } from './components/vendor/propertysidebar/propertysidebar.component';
import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { ProjectModulesComponent } from './components/vendor/project-modules/project-modules.component';
import { UploadVideoProgressComponent } from './shared/components/upload-video-progress/upload-video-progress.component';
import { AddRentComponent } from './components/Rent/add-rent/add-rent.component';
import { RentPostDetailsComponent } from './components/Rent/rent-post-details/rent-post-details.component';
import { RentPostFeaturesComponent } from './components/Rent/rent-post-features/rent-post-features.component';
import { RentPostGalleryComponent } from './components/Rent/rent-post-gallery/rent-post-gallery.component';
import { RentPostVideoComponent } from './components/Rent/rent-post-video/rent-post-video.component';
import { RentPropertyHighlightsComponent } from './components/Rent/rent-property-highlights/rent-property-highlights.component';
import { RentPropertyLocationHighlightsComponent } from './components/Rent/rent-property-location-highlights/rent-property-location-highlights.component';
import { RentPostPublishComponent } from './components/Rent/rent-post-publish/rent-post-publish.component';
import { RentSidebarComponent } from './components/Rent/rent-sidebar/rent-sidebar.component';
import { RentPostLocationComponent } from './components/Rent/rent-post-location/rent-post-location.component';




@NgModule({
  declarations: [
    AppComponent,
    PostPropertyComponent,
    PostLocationComponent,
    PostDetailsComponent,
    PostFeaturesComponent,
    PostGalleryComponent,
    PostPlanComponent,
    PostPublishComponent,
    PropertylocationComponent,
    VendorDashboardComponent,
    LoanComponent,
    FloorplanComponent,
    SpecificationsComponent,
    PropertyhighlightsComponent,
    SidebarComponent,
    MyPropertiesComponent,
    MyProjectsComponent,
    CommercialPropertiesComponent,
    LeadcenterComponent,
    DealsComponent,
    AddCompanyComponent,
    CompanySidebarComponent,
    CompanyinfoComponent,
    CompanyimagesComponent,
    AddsuccessfulprojectComponent,
    SuccessProjectsImagesComponent,
    AddOngoingProjectsComponent,
    OngoingProjectImagesComponent,
    CompanyProfileComponent,
    AddProjectComponent,
    ProjectSidebarComponent,
    ProjectLocationComponent,
    ProjectinfoComponent,
    ProjectadditionalinfoComponent,
    ProjectgalleryComponent,
    BrochureComponent,
    ProjecthighlightsComponent,
    ProjectlocationhighlightsComponent,
    ProjectPublishComponent,
    ProjectfloorplanComponent,
    ProjectSpecificationsComponent,
    UnitsComponent,
    PropertysidebarComponent,
    MyLeadsComponent,
    MyviewLeadsComponent,
    MyCompanyComponent,
    ProjectModulesComponent,
    UploadVideoProgressComponent,
    AddRentComponent,
    RentPostDetailsComponent,
    RentPostFeaturesComponent,
    RentPostGalleryComponent,
    RentPostVideoComponent,
    RentPropertyHighlightsComponent,
    RentPropertyLocationHighlightsComponent,
    RentPostPublishComponent,
    RentSidebarComponent,
    RentPostLocationComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    NgxPaginationModule,
    NgbModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 10000
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWb9Xof86boGQouKT9E4hv0ipuu7LyQJM',
      libraries: ['places']
    }),
    NguCarouselModule,
    ImageCropperModule,
    OwlModule
  ],
  providers: [
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [InitialService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initEnvironment,
      deps: [LoaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function appInit(initService: InitialService): Function {
  return () => initService.loadApp();
}
export function initEnvironment(loaderService: LoaderService): Function {
  return () => loaderService.loadLocation();
}
