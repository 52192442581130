import { Component, OnInit } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-my-leads',
  templateUrl: './my-leads.component.html',
  styleUrls: ['./my-leads.component.css']
})
export class MyLeadsComponent implements OnInit {
  getMylead:any;
    constructor(
    private rsbService:RsbService,
    private rsbLookup:RsbLookupModel
  ) { 
    console.log("mylead")
  }

  ngOnInit(): void {
    console.log("my lead")
  console.log(JSON.stringify(this.getMylead))
  console.log(this.rsbLookup.getLoggedUser())
  this.getMyleads()
  }

  getMyleads() {
    this.rsbService.myLeads().subscribe((rsp) => {
      this.getMylead = rsp.contents;


    })

  }

}
